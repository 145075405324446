<template>
    <div>
        <Navbar mode="solid" class="navbar" :show-btn-icon="true" :show-avatar="false" :show-btns="false"
            :btn-link-path="backPath" />
        <main class="main">
            <div class="wrapper">
                <h1 class="page-title" v-t="'page.group_tour.accommodation.title'" />
                <p class="page-description" v-t="'page.group_tour.accommodation.sub_title'" />
                <div class="form">
                    <div class="price--items--container">
                        <div class="price--item">
                            <div class="price--top--container">
                                <div class="price--text--container" property="description">
                                    <h3 class="price--title">
                                        <i class="bx bxs-user bx-xs"> </i>
                                        {{ $t("page.group_tour.section.price.single_room") }}
                                    </h3>
                                    <p class="price--description" v-t="'page.group_tour.section.price.single_room.text'" />
                                </div>
                                <div class="price--container" property="offers" typeof="Offer">
                                    <span class="price">
                                        {{
                                                                                tour.single_room_price
                                                                                | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                                }}
                                    </span>
                                    <span class="price--unit" v-t="'common.per_traveller'" />
                                </div>
                            </div>
                            <SelectDropdown class="mt-8" :show-description="false" :optionsList="singleRooms"
                                v-model="options.singleRooms"
                                :label="$t('page.group_tour.accommodation.how_many_single_rooms')" :show-label="true"
                                :disabled="disableSingleRooms" ref="singleRooms" :show-err-msg="isSingleRoomError"
                                :error-msg="singleRoomsErrMsg" />
                        </div>
                        <Divider class="my-8" />
                        <div class="price--item">
                            <div class="price--top--container">
                                <div class="price--text--container" property="description">
                                    <h3 class="price--title">
                                        <i class="bx bxs-group"> </i> {{ $t("page.group_tour.section.price.shared_room") }}
                                    </h3>
                                    <p class="price--description" v-t="'page.group_tour.section.price.shared_room.text'" />
                                </div>
                                <div class="price--container" property="offers" typeof="Offer">
                                    <span class="price">
                                        {{
                                                                                tour.shared_room_price
                                                                                | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                                }}
                                    </span>
                                    <span class="price--unit" v-t="'common.per_traveller'" />
                                </div>
                            </div>
                            <SelectDropdown :show-description="false" :optionsList="sharedRooms"
                                v-model="options.sharedRooms" :show-label="true" class="mt-8"
                                :label="$t('page.group_tour.accommodation.how_many_shared_rooms')"
                                :disabled="disableSharedRooms" ref="sharedRooms" :show-err-msg="isSharedRoomError"
                                :error-msg="sharedRoomsErrMsg" />
                        </div>
                    </div>

                    <Button :text="$t('trip_request.button')" class="desktop-cta" kind="primary"
                        @click.native="goToNextPage()" />
                </div>
            </div>
        </main>
        <div class="bottom-nav">
            <div class="bottom-nav-wrapper">
                <Button class="block h-12 px-8 w-full" :text="`${$t('trip_request.button')}`" kind="primary"
                    @click.native="goToNextPage()" />
            </div>
        </div>
    </div>
</template>

<script>
import SelectDropdown from "@/components/dropdowns/SelectDropdown";
import { mapActions, mapGetters } from "vuex";
import Divider from "@/components/dividers/Divider.vue";
import Button from "@/components/buttons/Button.vue";

export default {
    name: "Accommodation",
    components: {
        SelectDropdown,
        Divider,
        Button,
    },
    data() {
        return {
            loading: false,
            options: {
                singleRooms: 0,
                sharedRooms: 0,
            },
            singleRoomsSet: {
                oldValue: 0,
                currentValue: 0,
            },
            sharedRoomsSet: {
                oldValue: 0,
                currentValue: 0,
            },
            isSingleRoomError: false,
            isSharedRoomError: false,
            totalTravellers: 0,
            sharedRooms: [],
            singleRooms: [],
            disableSingleRooms: false,
            disableSharedRooms: false,
        };
    },
    computed: {
        ...mapGetters({
            getCurrency: "global/currency",
            travellers: "tour/travellers",
            totalSingleRooms: "tour/singleRooms",
            totalSharedRooms: "tour/sharedRooms",
            tour: "tour/tour",
            rates: "exchange/fixedRates",
        }),
        backPath() {
            return `/book/details?id=${this.$route.query.id}&date_id=${this.$route.query.date_id}&trip_date=${this.$route.query.trip_date}&origin=${this.$route.query.origin}`;
        },
        singleRoomsErrMsg() {
            return "Assign a single or shared room for each traveller";
        },
        sharedRoomsErrMsg() {
            return "Assign a single or shared room for each traveller";
        },
    },
    watch: {
        "options.singleRooms"(newValue, oldValue) {
            this.reset();
            this.singleRoomsSet.currentValue = Number(newValue);
            this.singleRoomsSet.oldValue = Number(oldValue);
            this.calculateRemainder(Number(newValue), Number(oldValue));
            this.resetOptions(Number(newValue), this.sharedRooms);
            this.setSingleRooms(Number(newValue));
            this.disableSharedRooms = this.setDisabled(
                Number(newValue),
                this.sharedRooms
            );
        },
        "options.sharedRooms"(newValue, oldValue) {
            this.reset();
            this.sharedRoomsSet.currentValue = Number(newValue);
            this.sharedRoomsSet.oldValue = Number(oldValue);
            this.calculateRemainder(Number(newValue), Number(oldValue));
            this.resetOptions(Number(newValue), this.singleRooms);
            this.setSharedRooms(Number(newValue));
            this.disableSingleRooms = this.setDisabled(
                Number(newValue),
                this.singleRooms
            );
        },
    },
    methods: {
        ...mapActions({
            setSingleRooms: "tour/setSingleRooms",
            setSharedRooms: "tour/setSharedRooms",
        }),
        calculateRemainder(newVal, oldVal) {
            if (newVal > oldVal) {
                let delta = newVal - oldVal;
                this.totalTravellers = this.totalTravellers - delta;
            }

            if (newVal < oldVal) {
                let delta = oldVal - newVal;
                this.totalTravellers = this.totalTravellers + delta;
            }
        },
        resetOptions(newValue, array) {
            let delta = array.length - 1 - newValue;
            array.forEach((option) => {
                if (option.value > delta) {
                    option.disabled = true;
                } else {
                    option.disabled = false;
                }
            });
        },
        setDisabled(newValue, array) {
            if (newValue === array.length - 1) {
                return true;
            } else {
                return false;
            }
        },
        validate() {
            if (
                this.options.sharedRooms === 0 &&
                this.options.singleRooms === 0
            ) {
                this.isSharedRoomError = this.isSingleRoomError = true;
                return true;
            } else if (this.totalTravellers !== 0) {
                this.$notify({
                    type: "error",
                    text: `${this.totalTravellers} traveller is not yet assigned a room.`,
                });
            } else {
                this.reset();
                return false;
            }
        },
        reset() {
            this.isSharedRoomError = this.isSingleRoomError = false;
        },
        goToNextPage() {
            if (this.validate() === false) {
                this.$router.push(
                    `/book/payment?id=${this.$route.query.id}&date_id=${this.$route.query.date_id}&trip_date=${this.$route.query.trip_date}&origin=${this.$route.query.origin}`
                );
            }
        },
        setSelectOptions() {
            let rooms = [
                { value: 0, name: 0, selected: true, disabled: false },
            ];
            this.totalTravellers = this.travellers.length;
            this.travellers.forEach((traveller, index) => {
                rooms.push({
                    value: index + 1,
                    name: index + 1,
                    selected: false,
                    disabled: false,
                });
            });
            return rooms;
        },
        getSingleRooms() {
            this.options.singleRooms = this.totalSingleRooms;
            let singleRooms = this.setSelectOptions();
            let singleValIndex = singleRooms.findIndex(
                (option) => option.value === this.totalSingleRooms
            );
            singleRooms[singleValIndex].selected = true;
            this.singleRooms = [...singleRooms];
        },
        getSharedRooms() {
            this.options.sharedRooms = this.totalSharedRooms;
            let sharedRooms = this.setSelectOptions();
            let sharedValIndex = sharedRooms.findIndex(
                (option) => option.value === this.totalSharedRooms
            );
            sharedRooms[sharedValIndex].selected = true;
            this.sharedRooms = [...sharedRooms];
        },
    },
    mounted() {

        this.getSingleRooms();
        this.getSharedRooms();
        
        if (this.totalSingleRooms === this.travellers.length) {
            this.disableSharedRooms = true;
        }

        if (this.totalSharedRooms === this.travellers.length) {
            this.disableSingleRooms = true;
        }
    },
};
</script>

<style scoped>
.navbar {
    @apply border-none;
}
.main {
    @apply pt-16 pb-32 px-4;
}

.wrapper {
    @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.bottom-nav {
    @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
    @apply block sm:hidden;
}

.bottom-nav-wrapper {
    @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
    @apply hidden sm:block h-12 px-8 w-full mt-8;
}
.page-title {
    @apply font-bold text-3xl text-black-base text-center;
}

.page-description {
    @apply text-black-lighter text-lg mb-12 text-center;
}

.price--items--container {
    @apply grid gap-4 mt-6;
}

.price--item {
}

.price--top--container {
    @apply flex items-start justify-between gap-8;
}

.price--title {
    @apply font-semibold text-black-base text-lg;
    @apply flex items-center gap-1;
}

.price--description {
    @apply text-sm text-black-lighter;
}

.price--container > span {
    @apply block;
}

.price {
    @apply font-bold text-base text-black-base text-right;
}

.price--unit {
    @apply text-black-lighter text-xs font-medium text-right;
}
</style>
