import { render, staticRenderFns } from "./Accommodation.vue?vue&type=template&id=18a41356&scoped=true"
import script from "./Accommodation.vue?vue&type=script&lang=js"
export * from "./Accommodation.vue?vue&type=script&lang=js"
import style0 from "./Accommodation.vue?vue&type=style&index=0&id=18a41356&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18a41356",
  null
  
)

export default component.exports